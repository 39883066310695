import type { TListTypes, TSizes } from '~/types/Shared.types';

const COMPONENT_NAME = 'shared-field';

export const fieldSizes: TListTypes<TSizes> = {
  large: `${COMPONENT_NAME}--large`,
  medium: `${COMPONENT_NAME}--medium`,
  small: `${COMPONENT_NAME}--small`,
  xs: `${COMPONENT_NAME}--xs`,
};
