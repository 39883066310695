<template>
  <SharedTransitionWrapper :show="isError" component="p" :transition-key="props.errorMessage">
    <SharedText :style="cssVars" class="error" v-bind="mergedStyles">{{ props.errorMessage }}</SharedText>
  </SharedTransitionWrapper>
</template>

<script setup lang="ts">
import { defaultStyle } from '~/components/SharedErrorText/SharedErrorText.data';
import type { ISharedErrorText } from '~/components/SharedErrorText/SharedErrorText.types';
import type { ITextProps } from '~/types/SharedFont.types';

const props = withDefaults(defineProps<Partial<ISharedErrorText>>(), {
  isError: false,
  left: '50%',
  textStyle: () => defaultStyle,
  top: '125%',
  width: '100%',
});

const mergedStyles = computed<ITextProps>(() => {
  const styles: ITextProps = {};
  for (const key in defaultStyle) {
    // @ts-ignore
    if (props.textStyle[key]) {
      // @ts-ignore
      styles[key] = props.textStyle[key];
    } else {
      // @ts-ignore
      styles[key] = defaultStyle[key];
    }
  }
  return styles;
});

const cssVars = computed(() => {
  return {
    '--error-left': props.left,
    '--error-top': props.top,
    '--error-width': props.width,
  };
});
</script>

<style src="./SharedErrorText.scss"></style>
