<template>
  <div :class="['shared-field', sharedFieldClasses]" :style="styles">
    <label :class="['shared-field__inner', classesLabel]" :style="stylesInner">
      <input
        v-model="field"
        :size="sizeMode"
        :class="['shared-field__input', classesInput]"
        :type="type"
        :style="stylesInput"
        :disabled="disabled"
        @blur="blurInput"
        @focus="focusInput"
      />
      <component
        :is="prependIcon.component"
        v-if="prependIcon"
        :key="prependIcon.key"
        v-bind="optionsPrependIcon"
        class="shared-field__icon shared-field__icon--prepend"
      />
      <SharedText
        v-if="placeholder && !field"
        v-bind="optionsPlaceholder"
        :class="['shared-field__placeholder', placeholderPosition]"
        >{{ props.placeholder }}
      </SharedText>
      <component
        :is="appendIcon.component"
        v-if="appendIcon"
        :key="appendIcon.key"
        v-bind="optionsAppendIcon"
        class="shared-field__icon shared-field__icon--append"
      />
    </label>
    <SharedErrorText :error-message="props.errorMessage" :is-error="!isValid" top="150%" />
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { IEmitSharedField, IPropsSharedField } from '~/components/SharedField/SharedField.types';
import { Colors } from '~/constants/colors.constants';
import { fieldSizes } from '~/components/SharedField/SharedField.data';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';

const MIN_INPUT_SIZE = 2;
const VAR_WIDTH_INNER = '--shared-field-width';
const VAR_HEIGHT_INNER = '--shared-field-height';
const VAR_PADDING = '--padding';

const isFocus = ref(false);

const { proceedCssValue } = GlobalUtils.CSS;

const emit = defineEmits<IEmitSharedField>();

const focusInput = () => {
  emit('onInputFocus');
  isFocus.value = true;
};
const blurInput = () => {
  emit('onInputBlur');
  isFocus.value = false;
};

const props = withDefaults(defineProps<IPropsSharedField>(), {
  borderRadius: 0,
  color: Colors.NEUTRAL.WHITE,
  disabled: false,
  errorMessage: '',
  fontSize: FontSizesNumber.LARGE,
  fontWeight: FontWeights.MEDIUM,
  inputStyle: 'purple',
  isValid: true,
  placeholderPosition: 'center',
  size: 'medium',
  textAlign: 'left',
  type: 'text',
});

const {
  width,
  height,
  backgroundColor,
  color,
  size,
  inputStyle,
  padding,
  modelValue,
  textAlign,
  fontSize,
  fontWeight,
  isValid,
  borderRadius,
  hoverColor,
} = toRefs(props);

const field = computed({
  get() {
    return modelValue.value;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const sizeMode = computed<number | undefined>(() => {
  return props.inputWidthBySize ? Math.max(props.modelValue.length, MIN_INPUT_SIZE) : undefined;
});

const sharedFieldClasses = computed(() => ({
  'shared-field__focus': isFocus.value,
}));

const classesInput = computed(() => {
  return {
    [fieldSizes[size.value]]: size.value,
    [inputStyle.value]: inputStyle.value,
  };
});

const classesLabel = computed(() => {
  return {
    'size-contolled': props.inputWidthBySize,
  };
});

const styles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  if (height?.value) properties[VAR_HEIGHT_INNER] = proceedCssValue(height.value);
  if (width?.value) properties[VAR_WIDTH_INNER] = proceedCssValue(width.value);
  if (padding?.value) properties[VAR_PADDING] = proceedCssValue(padding.value);
  if (!isValid.value) properties['--error-margin'] = '20px';
  return properties;
});

const stylesInner = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  if (height?.value) properties[VAR_HEIGHT_INNER] = proceedCssValue(height.value);
  if (width?.value) properties[VAR_WIDTH_INNER] = proceedCssValue(width.value);
  return properties;
});

const stylesInput = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};

  if (backgroundColor?.value) {
    const colorsStyles = GlobalUtils.CSS.parseColor(backgroundColor.value);
    if (colorsStyles.backgroundColor) properties.backgroundColor = colorsStyles.backgroundColor;
    properties.backgroundImage = colorsStyles.backgroundImage;
  }

  properties['--input-text-align'] = textAlign.value;
  properties['--hover-color'] = hoverColor?.value || color.value;
  properties['--font-size'] = GlobalUtils.Converting.toPx(fontSize.value);
  properties['--font-weight'] = fontWeight.value;
  properties['--border-radius'] = GlobalUtils.Converting.toPx(borderRadius.value);
  return properties;
});
const hoverColorInput = computed(() => (props.hoverColor ? props.hoverColor : props.color));
</script>

<style src="./SharedField.scss" scoped lang="scss"></style>
<style lang="scss" scoped>
.shared-field {
  --hover-color-input: v-bind(hoverColorInput);
  --color-input: v-bind(color);
}
</style>
